import { action, runInAction, observable, computed } from 'mobx'
import { task } from 'mobx-task'
import { extractMessageFromError } from '@taxfyle/web-commons/lib/utils/errorUtil'
import { browserHistory } from 'react-router'
import links from '../../misc/links'
import QS from 'qs'

/**
 * Responsible for managing the current embedded room view model.
 */
export default class ProjectRoomViewModel {
  @observable
  job

  @observable
  room

  @observable
  roomUrl

  @observable
  qs

  constructor({ flashMessageStore, sessionStore, projectStore, roomStore }) {
    this.flashMessageStore = flashMessageStore
    this.sessionStore = sessionStore
    this.projectStore = projectStore
    this.roomStore = roomStore
  }

  @computed
  get workspace() {
    return this.sessionStore.workspace
  }

  @computed
  get member() {
    return this.sessionStore.member
  }

  @computed
  get hideNavbar() {
    return this.qs?.hideNavbar === 'true'
  }

  @computed
  get headerProps() {
    const { simplifiedNavBar } =
      this.sessionStore.workspace.features.jobDetailsConfig
    return (
      simplifiedNavBar.enabled && {
        simplified: true,
        simplifiedLogo: simplifiedNavBar.image,
        backTargetUrl: simplifiedNavBar.targetUrl,
      }
    )
  }

  @computed
  get composedRoomUrl() {
    return `${this.roomUrl}&displayName=${this.member.displayName}`
  }

  @action.bound
  redirectBack() {
    if (this.job) {
      browserHistory.replace(links.projectDetails(this.job.id, this.hideNavbar))
      return
    }
    browserHistory.replace(links.projects())
  }

  activate = task(async (jobId, roomId, queryParams) => {
    try {
      const [job, room] = await Promise.all([
        this.projectStore.fetchProject(jobId),
        this.roomStore.fetchRoom(roomId),
      ])

      runInAction(() => {
        this.qs = QS.parse(queryParams, { ignoreQueryPrefix: true })
        this.job = job
        this.room = room
      })

      if (this.room.status !== 'Active') {
        this.redirectBack()
        return
      }

      const roomDescriptor = await this.roomStore.joinRoom(roomId)
      switch (roomDescriptor.type) {
        case 'Whereby':
          runInAction(() => {
            this.roomUrl = new URL(roomDescriptor.roomUrl)
          })
          return
        default:
          this.redirectBack()
      }
    } catch (err) {
      this.flashMessageStore.create(extractMessageFromError(err)).failed()
      this.redirectBack()
    }
  })
}
